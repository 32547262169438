<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    :persistent="isMakingShareRequest"
    max-width="680"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between primary white--text pb-4">
        Share this report

        <v-btn
          text
          color="buttonPrimaryText"
          class="primary lighten-1"
          @click="copyLinkToClipboard"
        >
          <v-icon left>
            link
          </v-icon>
          Copy URL
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <!-- Show the inputs for form values -->
        <v-row>
          <!-- First show the options about team access -->
          <v-col
            v-if="isWhitelisted"
            cols="12"
            md="6"
          >
            <v-select
              v-model="form.teamBehaviour"
              :items="teamBehaviourOptions"
              :label="form.teamBehaviour === 'hidden' ? 'For your teammates' : 'Let your teammates'"
              :disabled="isMakingTeamBehaviourRequest"
              :loading="isMakingTeamBehaviourRequest"
              @change="handleTeamBehaviourChange"
              color="primary"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>

          <!-- Then show the options about public access -->
          <v-col
            cols="12"
            :md="isWhitelisted ? 6 : 12"
          >
            <v-select
              v-model="form.isShareable"
              :items="isShareableOptions"
              label="Who can see this report?"
              :disabled="isMakingShareableRequest"
              :loading="isMakingShareableRequest"
              @change="handleShareableRequest"
              color="primary"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>

          <!-- If we did allow anyone to see it -->
          <v-col
            v-if="form.isShareable"
            cols="12"
          >
            <v-text-field
              :value="shareableURL"
              label="Click to copy shareable URL"
              @click="copyLinkToClipboard"
              color="primary"
              hide-details
              readonly
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Show a divider -->
        <v-divider class="my-6" />

        <div>
          You can share this report with people who are not your team members.
        </div>

        <div class="pt-2">
          You may also invite your selected team members to grant them write access and keep your other team members on read-only permission.
        </div>

        <!-- Show the input for inviting new members -->
        <v-text-field
          v-model.trim="form.memberEmail"
          label="Add a new member"
          placeholder="Enter Email Address"
          color="primary"
          class="mt-4"
          append-icon="send"
          @click:append="handleInviteCreate"
          @keypress.enter="handleInviteCreate"
          :loading="isMakingCreateMemberRequest"
          :disabled="isMakingCreateMemberRequest"
          hide-details
          outlined
          dense
        ></v-text-field>

        <!-- Show current report members -->
        <div class="pt-3">
          <div
            v-for="item in overview.members"
            :key="'member-' + item.id"
            class="d-flex align-center mt-3"
          >
            <!-- Show the avatar first -->
            <user-avatar :name="item.user.name" />

            <!-- Show the name and email -->
            <div class="px-3 flex-grow-1">
              <div>
                {{ item.user.name }}
              </div>

              <div>
                {{ item.user.email }}
              </div>
            </div>

            <!-- Show action buttons -->
            <v-menu
              :disabled="isMakingUpdateMemberRequest || isMakingDeleteMemberRequest"
              transition="slide-y-transition"
              :close-on-content-click="false"
              offset-y
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <!-- Show the menu options -->
              <v-list width="200" dense>
                <!-- Show the update button -->
                <v-list-item
                  @click="handleInviteUpdate(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Grant Write Access
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                      :value="item.can_write"
                      hide-details
                    />
                  </v-list-item-action>
                </v-list-item>

                <!-- Show the delete button -->
                <v-list-item
                  @click="handleInviteDelete(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Remove Access
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// Import helper functions
import { email } from "vuelidate/lib/validators"

// Import children components
const UserAvatar = () => import(/* webpackChunkName: "user-avatar" */ "@/blocks/common/UserAvatar")

// Export the SFC
export default {
  // Name of the component
  name: "ShareDialog",

  // Register the components
  components: {
    UserAvatar,
  },

  // Define the props
  props: {
    // Whether or not to show the dialog
    value: {
      type: Boolean,
      default: false,
    },

    // Which module is this dialog about
    module: String,

    // The overview data object
    overview: Object,
  },

  // Define local data variables
  data: () => ({
    // Whether or not is the app making a share related request
    isMakingShareRequest: false,
    // Whether or not is the teamBehaviour value changing
    isMakingTeamBehaviourRequest: false,
    // Whether or not is the isShareable value changing
    isMakingShareableRequest: false,
    // Whether or not is making a new member
    isMakingCreateMemberRequest: false,
    // Whether or not is updating a member
    isMakingUpdateMemberRequest: false,
    // Whether or not is deleting a member
    isMakingDeleteMemberRequest: false,

    form: {
      teamBehaviour: "hidden",
      isShareable: false,
      memberEmail: "",
    },

    teamBehaviourOptions: [
      {
        text: "Hide from them",
        value: "hidden"
      },
      {
        text: "See this report",
        value: "read-only"
      },
      {
        text: "Edit this report",
        value: "read-write"
      }
    ],

    isShareableOptions: [
      {
        text: "Only authorized people",
        value: false
      },
      {
        text: "Let anyone with a link to see it",
        value: true
      },
    ],

    // Create a map for module and view
    moduleViewMap: {
      influencerGroup: "InfluencerGroupView",
      mentionTracking: "MentionTrackingView",
      campaignTracking: "CampaignTrackingView",
      mentionTrackingGroup: "MentionTrackingGroupView",
    },

    moduleEndpointMap: {
      influencerGroup: "influencer-groups",
      mentionTracking: "mention-tracking",
      campaignTracking: "campaign-tracking",
      mentionTrackingGroup: "mention-tracking-groups",
    }
  }),

  // Define computable properties
  computed: {
    /**
     * Get the shareable URL in string
     *
     * @returns {String}
     */
    shareableURL() {
      return `${window.location.origin}${this.$router.resolve({ name: this.moduleViewMap[this.module] }).href}`
    },

    /**
     * Get the logged in user's data object
     *
     * @returns {Object}
     */
    profileData() {
      return this.$store.getters["auth/profile"]
    },

    /**
     * Whether or not the currently authenticated user is whitelisted or not
     *
     * @returns {Boolean}
     */
    isWhitelisted() {
      return this.profileData ? this.profileData.is_whitelisted : false
    },
  },

  // Define local method functions
  methods: {
    /**
     * Trigger and handle the request to change team-behaviour value
     *
     * @returns {void}
     */
    async handleTeamBehaviourChange() {
      // Set the loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingTeamBehaviourRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making the network request
      try {
        await axios({
          url: `/api/${this.moduleEndpointMap[this.module]}/${this.overview.model.id}/team-behaviour`,
          method: "PUT",
          data: {
            team_behaviour: this.form.teamBehaviour
          }
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Report updated!" })

        // Update the local store value
        this.$store.dispatch(`${this.module}/updateModel`, { ...this.overview.model, team_behaviour: this.form.teamBehaviour })
      }
      // Catch the error
      catch (error) {
        // Log the error
        logger({ type: "ShareDialog/TeamBehaviour Update Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nevertheless
      finally {
        // Remove the loaders
        this.isMakingShareRequest = false
        this.isMakingTeamBehaviourRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Trigger and handle the is_shareable value change
     *
     * @returns {void}
     */
    async handleShareableRequest() {
      // Set the loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingShareableRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making the network request
      try {
        await axios({
          url: `/api/${this.moduleEndpointMap[this.module]}/${this.overview.model.id}/is-shareable`,
          method: "PUT",
          data: {
            is_shareable: this.form.isShareable
          }
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Report updated!" })

        // Update the local store value
        this.$store.dispatch(`${this.module}/updateModel`, { ...this.overview.model, is_shareable: this.form.isShareable })
      }
      // Catch the error
      catch (error) {
        // Log the error
        logger({ type: "ShareDialog/IsShareable Update Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nevertheless
      finally {
        // Remove the loaders
        this.isMakingShareRequest = false
        this.isMakingShareableRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Validate and make a network request for members
     *
     * @returns {void}
     */
    async handleInviteCreate() {
      // If we're already making a request
      if (this.isMakingCreateMemberRequest) {
        // Stop further execution
        return
      }

      // If the email input value isn't valid
      if (this.form.memberEmail.length === 0 || email(this.form.memberEmail) === false) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "Invalid email address" })

        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingCreateMemberRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making a request
      try {
        // Use helper function
        const response = await axios({
          url: `/api/${this.moduleEndpointMap[this.module]}/${this.overview.model.id}/members`,
          data: { email: this.form.memberEmail.trim().toLowerCase() },
          method: "POST"
        })

        // If we have the response, update the list
        this.$store.dispatch(`${this.module}/updateMember`, { id: this.overview.model.id, member: response.data })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Added as a report member!" })

        // Reset the form
        this.form.memberEmail = ""
      }
      // Catch any error
      catch (error) {
        // Log the response
        logger({ type: "ShareDialog/CreateMember Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nonetheless
      finally {
        this.isMakingShareRequest = false
        this.isMakingCreateMemberRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Toggle the can write access value
     *
     * @param {Object} member
     * @returns {void}
     */
    async handleInviteUpdate(member) {
      // If we're already making a request
      if (this.isMakingUpdateMemberRequest) {
        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingUpdateMemberRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making a request
      try {
        // Use helper function
        const response = await axios({
          url: `/api/${this.moduleEndpointMap[this.module]}/${this.overview.model.id}/members/${member.id}`,
          data: { can_write: !member.can_write },
          method: "PUT"
        })

        // If we have the response, update the list
        this.$store.dispatch(`${this.module}/updateMember`, { id: this.overview.model.id, member: response.data })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Updated member permission!" })
      }
      // Catch any error
      catch (error) {
        // Log the response
        logger({ type: "ShareDialog/UpdateMember Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nonetheless
      finally {
        this.isMakingShareRequest = false
        this.isMakingUpdateMemberRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Remove the member from the table
     *
     * @param {Object} member
     * @returns {void}
     */
    async handleInviteDelete(member) {
      // If we're already making a request
      if (this.isMakingDeleteMemberRequest) {
        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingDeleteMemberRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making a request
      try {
        // Use helper function
        await axios({
          url: `/api/${this.moduleEndpointMap[this.module]}/${this.overview.model.id}/members/${member.id}`,
          method: "DELETE"
        })

        // If we have the response, update the list
        this.$store.dispatch(`${this.module}/removeMember`, { id: this.overview.model.id, member: member })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Removed member from report!" })
      }
      // Catch any error
      catch (error) {
        // Log the response
        logger({ type: "ShareDialog/RemoveMember Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nonetheless
      finally {
        this.isMakingShareRequest = false
        this.isMakingDeleteMemberRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Copy the shareable link to the clipboard
     *
     * @returns {void}
     */
    copyLinkToClipboard() {
      // Copy the link value
      navigator.clipboard.writeText(this.shareableURL)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Set the default form values
    this.form.teamBehaviour = this.overview.model.team_behaviour
    this.form.isShareable = this.overview.model.is_shareable
  }
}
</script>
